<script setup>
import { ref, defineAsyncComponent } from 'vue'
// import { useAuthStore } from '@/store/auth'

const DaftarPenjualan = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/DaftarPenjualan.vue')
)

const DetailPenjualan = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/DetailPenjualan.vue')
)

const PromoPenjualan = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/PromoPenjualan.vue')
)

// const auth = useAuthStore()

let laporan = ref('DaftarPenjualan')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Laporan Daftar Penjualan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'DaftarPenjualan'
    },
    visible: true, 
  },
  {
    label: 'Laporan Detail Penjualan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'DetailPenjualan'
    },
    visible: true,
  },
  {
    label: 'Laporan Promo Penjualan ',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'PromoPenjualan'
    },
    visible: true,
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Laporan Penjualan</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Laporan / Penjualan</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <DaftarPenjualan
            v-if="laporan === 'DaftarPenjualan'"
            @toggle="onToggle"
          />
          <DetailPenjualan
            v-if="laporan === 'DetailPenjualan'"
            @toggle="onToggle"
          />
          <PromoPenjualan
            v-if="laporan === 'PromoPenjualan'"
            @toggle="onToggle"
          />
        </div>
      </div>
    </div>
  </div>
</template>
